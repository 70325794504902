import React from "react";
import bannerImage from "./assets/undraw_under_construction_46pa.svg";

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

function App() {
  const [navbarHeight] = React.useState(140);
  const [footerHeight] = React.useState(60);
  const [inputHeight] = React.useState(48);

  const [email, setEmail] = React.useState();
  const [submitted, setSubmitted] = React.useState();

  const handleSubmit = () => {
    if (email) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "newsletter", email })
      })
        .then(() => {
          setSubmitted(true);
        })
        .catch(error => alert(error));
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handleEnterKeyPress = (e) => {
    e.preventDefault();
    handleSubmit();
  }

  return (
    <div
      className="bg-light"
      style={{
        boxSizing: "border-box",
        height: `100vh`,
        maxHeight: `100vh`,
        width: "100vw",
      }}
    >
      <div className="container-fluid px-5 h-100">
        <div
          className="row align-items-center"
          style={{ height: navbarHeight }}
        >
          <div className={`col`}>
            {/* <img src="http://placehold.it/100x50" alt="VistaCorp logo" /> */}
            <h4>
              <strong>VistaCorp</strong>
            </h4>
          </div>
        </div>
        <div
          className={`row align-items-center`}
          style={{
            height: `calc(100vh - ${navbarHeight}px - ${footerHeight}px)`,
          }}
        >
          <div className={`col col-md-4 offset-md-1`}>
            <div style={{ marginBottom: navbarHeight }}>
              <h5 className="text-secondary text-uppercase small mb-4">
                Coming soon
              </h5>
              <p className={`mb-4`}>Our website is under construction. Sign up below to receive an update when we launch.</p>
              <h1 className={`mb-4`}>
                <strong>
                  Get notified <br /> when we launch
                </strong>
              </h1>
              <div
                className={`d-flex flex-column flex-md-row align-md-items-center w-100`}
                style={{ maxWidth: 540 }}
              >
                {submitted ? (
                  <div className={`text-success`}>Thank you!</div>
                ) : (
                  <React.Fragment>
                    <form 
                      name="newsletter" 
                      method="post"
                      onSubmit={handleEnterKeyPress}
                      className={`d-flex flex-column flex-md-row align-md-items-center w-100`}
                      style={{ maxWidth: 540 }}
                    >
                      <input type="hidden" name="form-name" value="newsletter" />
                      <input
                        type="email"
                        name="email"
                        className="form-control bg-white w-100 mb-3 mb-md-0"
                        style={{ height: inputHeight }}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder={`Email address`}
                        value={email}
                        onChange={handleEmailChange}
                      />
                      <button
                        className="btn btn-dark ml-md-3 px-4 py-2"
                        style={{ height: inputHeight }}
                        onClick={handleSubmit}
                      >
                        Subscribe
                      </button>
                    </form>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          <div
            className={`col d-none d-md-block`}
            style={{ position: "relative", height: "75vh" }}
          >
            <img
              alt="Banner"
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                right: `-15%`,
                objectFit: "contain",
              }}
              src={bannerImage}
            />
          </div>
        </div>
        <div
          className={`row d-flex align-items-center`}
          style={{ height: footerHeight }}
        >
          <div className={`col text-secondary`}>
            <small>© VistaCorp {new Date().getFullYear()}</small>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
